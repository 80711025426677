#box-sidebar-right {
    background-color: var(--sidebar-bg);
    width: 280px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    min-width: 280px;
    position: absolute;
    right: 0;
    box-shadow: 0px 3px 5px 2px var(--grey-100);
}  