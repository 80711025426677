@keyframes progressBar {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes slideIn {
  0% {
    top: -90px;
  }
  100% {
    top: 150px;
  }
}

@keyframes slideOut {
  0% {
    top: 110px;
  }
  100% {
    top: -90px;
  }
}

#root-alert {
  height: 0;
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 999;
}
.box-alert {
  background-color: var(class);
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  color: #333;

  display: flex;
  font-size: 1.1em;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 250px;
}
.box-alert > div {
  padding: 8px 8px 8px 15px;
}
.alert-close {
  align-items: center;
  align-self: flex-start;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 25px;
  min-width: 25px;
  margin: 4px 4px 0 0;
  padding: 0;
}
.alert-close:hover {
  background-color: transparent;
}
.alert-close:focus {
  position: relative;
  top: 1px;
  outline: none;
}

.alert-primary {
  background-color: var(--primary-light);
  border: 2px solid var(---primary-bg);
  color: var(--primary-bg);
}
.alert-primary .alert-close {
  // background-color: var(--primary-bg);
  color: var(--primary-bg);
}
.alert-primary .alert-close:hover {
  // background-color: var(--primary-bgh);
  color: var(--primary-bgh);
}

.alert-secondary {
  background-color: var(--secondary-light);
  border: 2px solid var(--secondary-bg);
  color: var(--black-color);
}
.alert-secondary .alert-close {
  // background-color: var(--secondary-bg);
  color: var(--secondary-bg);
}
.alert-secondary .alert-close:hover {
  // background-color: var(--secondary-bgh);
  color: var(--secondary-bgh);
}

.alert-success {
  background-color: var(--success-light);
  border: 2px solid var(--success-bg);
  color: var(--success-bg);
}
.alert-success .alert-close {
  // background-color: var(--success-bg);
  color: var(--success-bg);
}
.alert-success .alert-close:hover {
  // background-color: var(--success-bgh);
  color: var(--success-bgh);
}

.alert-error {
  background-color: var(--error-light);
  border: 2px solid var(--error-bg);
  color: var(--error-bg);
}
.alert-error .alert-close {
  // background-color: var(--error-bg);
  color: var(--error-bg);
}
.alert-error .alert-close:hover {
  // background-color: var(--error-bgh);
  color: var(--error-bgh);
}

.alert-warning {
  background-color: var(--warning-light);
  border: 2px solid var(--warning-bg);
  color: var(--warning-bg);
}
.alert-warning .alert-close {
  // background-color: var(--warning-bg);
  color: var(--warning-bg);
}
.alert-warning .alert-close:hover {
  // background-color: var(--warning-bgh);
  color: var(--warning-bgh);
}

.alert-info {
  background-color: var(--info-light);
  border: 2px solid var(--info-bg);
  color: var(--info-bg);
}
.alert-info .alert-close {
  // background-color: var(--info-bg);
  color: var(--info-bg);
}
.alert-info .alert-close:hover {
  // background-color: var(--info-bgh);
  color: var(--info-bgh);
}