#box-sidebar-left {
  background-color: var(--sidebar-bg);
  width: 280px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  min-width: 280px;

  .box-logo{
    padding: 20px 15px;
    margin-bottom: 20px;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div{
      margin-right: 0px;
      svg{
        max-width: 150px;
      }
      img{
        width: 100%;
        max-width: 118px;
      }
    }
    h1{
      color: var(--primary-bg);
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      align-self: flex-end;
    }
    > img{
      width: auto;
      max-height: 100%;
    }
    &-close{
      display: none;
      svg{
        font-size: 35px;
      }
    }
  }
  .box-sidebar-left-scrool{
    overflow: auto;
    height: 100%;
    padding-top: 20px;
  }
}

@media (max-width: 780px) {
  #box-sidebar-left {
    position: absolute;
    z-index: 99;
    display: none;
    width: 100%;
    &.open-sidebar {
      display: block;
    }
    .box-logo{
      margin-bottom: 0px;
      &-close{
        display: block;
      }
    }
    .box-sidebar-left-scrool{
      padding: 0;
    }
  }
}