.box-meus-projetos{
    .box-content-topinfo{
        button{
            padding: 6px 20px;
            >svg{
                margin-right: 6px;
            }
        }
    }
    .list-box thead td{
        text-transform: uppercase;
    }
    .info-projeto{
        display: flex;
        align-items: center;
        .iconeprojeto{
            margin-right: 10px;
        }
        p{
            color: var(--grey-200);
            margin: 0;
        }
    }
    .list-box{
        span{
            white-space: nowrap;

            // Estilo para o status não clicável
            &.status-non-clickable {
                background-color: transparent; // Remove o box
                color: var(--black); // Define o texto preto
                padding: 0; // Remove o espaçamento interno
                border: none; // Remove bordas, caso existam
            }
        }
    }
}
.list-box{
    .form-box{
        margin: 0;
        padding: 0;
    }
    .link {
        &.default{
            color: var(--grey-200);
        }
        > svg {
            font-size: 13px;
        }
    }
    .actions{
        padding: 10px !important;
        &.onlysvg{
            svg {
                font-size: 17px;
            }
        }
    }
}
.box-meus-projetos-topinfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;
    button + button{
        margin-left: 10px
    }
}

@import './criar/criar.scss';
@import './configurar/configurar.scss';