.box-btn {
  display: flex;
  justify-content: flex-end;
  .btn + .btn{
    margin-left: 10px;
  }
}
.box-telaDesenvolvimento{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 85px);
  h1{
    text-align: center;
    strong{
      display: block;
      color: var(--primary-bg);
      font-size: 28px;
    }
  }
}

.custom-tooltip{
  background-color: var(--page-content-bg);
  border: 1px solid var(--grey-100);
  padding: 10px;
  border-radius: 8px;
  .label{
      border-bottom: 1px solid var(--grey-100);
      padding-bottom: 8px;
      font-weight: 600;
  }
  .desc{
      display: flex;
      align-items: center;
      margin: 5px 0;
      span{
          display: inline-block;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          margin-right: 8px;
      } 
  }
}
.custom-legend{
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  top: 10px;
  &.legend{
    &-left{
      justify-content: flex-start;
    }
    &-right{
      justify-content: flex-end;
    }
  }
  >div{
      display: flex;
      align-items: center;
      margin: 5px 10px 5px 0;
      span{
          display: inline-block;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          margin-right: 8px;
      } 
  }
}

.pageloadview-message{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  &-erro{
    font-size: 16px;
    font-weight: 500;
  }
}

.box-alerta{
  padding: 10px 15px;
  text-align: center;
  width: 100%;
  &.danger{
    background-color: var(--error-light);
  }
  p{
    margin: 0;
  }
}

.flag{
    &-success{
        background-color: var(--success-light);
        color: var(--success-bg);
        border-radius: 5px;
        padding: 4px 10px;
    }
    &-danger{
        background-color: var(--error-light);
        color: var(--error-bg);
        border-radius: 5px;
        padding: 4px 10px;
    }
    &-info{
        background-color: var(--info-light);
        color: var(--info-bg);
        border-radius: 5px;
        padding: 4px 10px;
    }
    &-primary{
        background-color: var(--primary-bg);
        color: var(--primary-color);
        border-radius: 5px;
        padding: 4px 10px;
    }
    &-warning{
        background-color: var(--warning-light);
        color: var(--warning-bg);
        border-radius: 5px;
        padding: 4px 10px;
    }
}

.text{
    &-success{
        color: var(--success-bg);
    }
    &-danger{
        color: var(--error-bg);
    }
    &-info{
        color: var(--info-bg);
    }
    &-primary{
        color: var(--primary-bg);
    }
    &-warning{
        color: var(--warning-bg);
    }
}

.iconeprojeto{
  border-radius: 50px;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: var(--white-color);
}

.colflex{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  >div{
    width: 100%;
  }
  &.col2{
    >div{
      width: calc((100% - (1 * 15px)) / 2);
      &:nth-of-type(2n){
        margin-left: 15px;
      }
    }
  }
  .col-btn{
    width: 1%;
    position: relative;
  }
  &.justify{
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    >div{
      width: auto;
      &.colflex-100{
        width: 100%;
      }
    }
  }
}

.box-btns{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 40px 0 20px 0;
  &.center{
    justify-content: center;
  }
  button + button{
    margin-left: 15px;
  }
}
    
.btn-colar{
    color: var(--grey-200);
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    &:hover{
        color: var(--grey-300);
    }
}