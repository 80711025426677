.box-login{
    &-frame{
        background-image: url('../../layout/img/login/bg-1.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        // background-color: #fcfbfa;
    
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100vh;
        overflow: hidden;
        >img{
            height: auto;
            max-height: 100%;
            width: auto;
            max-width: 100%;
        }
    }
    &-form{
        display: flex;
        align-items: center;
        justify-content: center;

        width: 50%;
        height: 100vh;
        overflow: auto;
    }
    &-logo{
        margin-bottom: 40px;
        max-width: 230px;
        >img{
            width: 100%;
        }
    }
    &-padlock{
        height: 34px;
        margin: -12px 0 20px 0;
        >img{
            height: 100%;
        }
    }
    &-info{
        margin-bottom: 16px;
        h2{
            color: var(--primary-bg);
            font-size: 44px;
            font-weight: 500;
            line-height: 44px;
            margin: 0;
        }
        h4{
            font-size: 26px;
            font-weight: 800;
            line-height: 30px;
        }
        p{
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
        }
    }
    &-container{
        display: flex;
        margin: auto;
        flex-direction: row;
        height: 100vh;
        align-items: center;
        justify-content: space-between;
    }
    &-content{
        max-width: 420px;
        width: 100%;
        margin: auto;
        padding: 30px 20px;
        &-senhahas{
            display: block;
            >label{
                display: block;
                &.has-success{
                    color: var(--success-bg);
                }
            }
        }
    }
    &-action{
        // margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        small{
            font-size: 11px;
        }
        >div{
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        >button{
            margin-top: 40px; 
        }
        &-btns{
            margin-top: 30px !important; 
            justify-content: flex-start !important;
            
            >button{
                // width: calc(50% - 10px); 
                +button{
                    margin-left: 10px;
                }
            }
        }
    }
    .regras-senha{
        display: flex;
        flex-direction: column;
        label{
            color: var(--error-bg);
            &.has-success{
                color: var(--success-bg);
            }
        }
    }
}


@media (max-width: 780px) {
    .box-login{
        &-frame{
            display: none !important;
        }
        &-form{
            width: 100% !important;
        }
    }
  }