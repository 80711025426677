.box-npsconcorrentesredes{
    h3{
        font-size: 20px;
    }
    &-topinfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        label{
            color: var(--grey-300);
            margin: 0;
        }
        >div{
            display: flex;
            align-items: center;
        }
        &-select{
            margin-right: 20px;
            .form-select-box{
                display: flex;
                align-items: center;
                >div{
                    margin-left: 5px;
                    // max-width: 150px;
                }
            }
        }
        &-avaliacao{
            label{
                margin-right: 5px;
            }
            strong{
                margin-left: 5px;
            }
        }
    }
    &-graficos{
        padding: 20px;
        &-chart{
            height: 500px;
            .pageloadview-message {
                display: flex; // Flexbox para posicionamento
                align-items: flex-start; // Alinha o conteúdo ao topo
                justify-content: center; // Centraliza horizontalmente
                height: 100%; // Garante que ocupa toda a altura do container
                font-size: 16px;
                color: var(--grey-300); // Ajuste da cor do texto
                text-align: center; // Centraliza o texto caso quebre em várias linhas
                padding-top: 20px; // Espaçamento entre o topo e o texto
                box-sizing: border-box; // Garante que o padding não afete o tamanho do box
            }
        }
    }
    &-analise-grafica{
        padding: 20px;
        p{
            color: var(--grey-300);
            font-size: 14px;
            line-height: 24px;
        }
    }

}



@media (max-width: 779px) {
    .box-npsconcorrentesredes{
        &-topinfo{
            flex-direction: column;
            &-select{
                .form-select-box{
                    flex-direction: column;
                }
            }
        }
    }
}