#box-app {
  display: block;
  width: 100%;
  height: 100vh;
  &-container{
    width: 100%;
  }
}

#box-container {
  overflow: auto;
  height: 100%;
  width: 100%;
  
  &-page{
    background-color: var(--background-container);
    color: var(--color-page);
    min-height: calc(100%);
    padding: 10px;
  }
  .box-titulo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .titulo {
      color: var(--color-sidebar);
      font-size: 1.7rem;
      font-weight: 700;
      margin: 0;
    }
  }
  .box-pesquisa {
    padding: 10px 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    > div{
      margin-left: 10px;
      &:first-child {
        min-width: 50%;
        margin-left: 0;
      }
    }
    .adicionar {
      margin-bottom: 15px;
    }
  }
}


.box-content{
  background-color: var(--page-content-bg);
  color: var(--page-content-color);
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px var(--grey-100);

  &-list{
    background-color: var(--page-content-bg);
    color: var(--page-content-color);
    padding: 0 0 5px 0;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px var(--grey-100);
  }
  &-topinfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 30px 0;
    h3{
      margin: 0;
    }
  }
}

@media (max-width: 780px) {
  #box-app {
    &-container{
      width: 100%;
      max-width: 100vw;
    }
  }
}

[id*='graficos-chart-box']{
  .graficos-chart-box-action{
    display: flex;
    justify-content: flex-end;
    &-title{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
  #graficos-chart{
    margin-bottom: 0px;
    >h3{
      display: none;
    }
  }
  &.graficos-download{
    .graficos-chart-box-action{
      display: none;
      &-title{
        margin-bottom: 0px;
        button{
          display: none;
        }
      }
    }
    #graficos-chart{
      margin-bottom: 20px;
      >h3{
        display: block;
      }
    }
  }
}

.page-alert{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  .modalAlerta-btns{
    margin: 10px 0 0 0
  }
}

@import './header.scss';
@import './footer.scss';
@import './modalInforme.scss';
@import './modalAlerta.scss';
@import './sidebarLeft.scss';