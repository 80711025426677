.box-banco-projetos{
    >div{
        display: flex;
        align-items: flex-start;
    }
    &-content{
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        width: 100%;
    }
    &-projeto{
        background-color: var(--page-content-bg);
        color: var(--page-content-color);
        padding: 15px;
        margin: 10px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 2px var(--grey-100);
        width: calc((100% - (3 * 20px)) / 3);
        label{
            display: block;
        }
        span{
            display: block;
        }
        &-topinfo{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            &-info{
                display: flex;
                align-items: center;
                >div:first-child{
                    margin-right: 10px;
                    max-width: 42px;
                }
            }
        }
        &-content{
            label{
                font-weight: 600;
            }
            &-descricao{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &-btns{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button + button{
                margin-left: 5px;
            }
        }
    }
    .box-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
            margin: 0;
        }
    }
    &-filtro{
        // background-color: var(--page-content-bg);
        // color: var(--page-content-color);
        // padding: 10px 15px;
        // margin: 10px;
        // border-radius: 5px;
        // box-shadow: 0px 0px 5px 2px var(--grey-100);
        // width: 200px;
        display: flex;
        align-items: center;
        .form-box{
            min-width: 300px;
            margin-left: 10px;
            .select-selected{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .box-filtro{
            margin-bottom: 20px;
            label{
                font-weight: bold;
            }
            .form-checkbox{
                flex-direction: column;
                align-items: flex-start;
                .check-box {
                    line-height: 18px;
                    display: initial;
                    margin-bottom: 10px;
                    .checkbox{
                        width: 15px;
                        height: 15px;
                        min-width: 15px;
                        min-height: 15px;
                        &::after{
                            display: none;
                        }
                    }
                }
            }
            .radio-button-container{
                flex-direction: column;
                align-content: flex-start;
                align-items: flex-start;
                .radio-box {
                    margin: 0;
                    border-bottom: 1px solid var(--grey-100);
                    width: 100%;
                }
            }
        }
    }
}


#root-modal-modalprojeto{
    .box-banco-projetos-projeto-topinfo-info{
        >div{
            display: flex;
            flex-direction: column;
        }
    }
    label{
        font-weight: bold;
    }
    .modalprojeto-empresas-virgula{
        span + span{
            &::before{
                content: ', ';
            }
        }   
    }
    .modalprojeto-periodos{
        margin-top: 40px;
        >div{
            display: flex;
            align-items: center;
            padding: 10px 0;
            button{
                padding: 4px 10px;
                min-height: 26px;
            }
        }
    }
    .modal-actions{
        justify-content: space-between;
        .modalprojeto-valores{
            .valorvelho{
                font-size: 14px;
                text-decoration: line-through;
            }
            .desconto{
                color: var(--primary-bg);
                font-size: 12px;
            }
            >div{
                display: flex;
                align-items: center;
            }
            h4{
                font-size: 20px;
                margin: 0 10px 0 0;
            }
        }
    }
}



@media (min-width: 601px) and (max-width: 990px) {
    .box-banco-projetos{
        .box-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            h3{
                padding: 10px 0 0 0;
            }
        }
        &-filtro{
            width: 100%;
            >div{
                width: 60%;
                max-width: 60%;
                +div{
                    width: 40%;
                    max-width: 40%;
                    margin-left: 15px;
                }
            }
            .form-box{
                margin: 0;
                .select-selected{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &-projeto{
            width: calc((100% - (2 * 20px)) / 2);
            margin: 10px;
        }
    }
}

@media (max-width: 600px) {
    .box-banco-projetos{
        .box-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            h3{
                padding: 10px 0 0 0;
            }
        }
        &-filtro{
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            >div{
                width: 100%;
            }
            .form-box{
                width: 100%;
                margin: 0;
                .select-selected{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &-projeto{
            width: calc((100%) / 1);
            margin: 10px 0 0 0;
        }
    }
}