.box-minhas-assinaturas{
    

}

#root-modal-assinaturas-adquirir-ativos{
    .periodos-lista{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div{
            display: flex;
            align-items: center;
            .btn.circle{
                margin-left: 6px;
                padding: 6px 15px;
            }
        }
        border-bottom: 1px solid var(--grey-100);
        padding: 0 0 20px 0;
    }
    .ativos-lista{
        &-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--grey-100);
            &-info{
                display: flex;
                flex-direction: column;
            }
            &-input{
                width: 150px;
            }
        }
        .flag-primary{
            text-align: center;
            font-size: 16px;
            padding: 10px 10px;
            margin: 25px 0;
            min-height: 38px;
        }
        &-title{
            padding: 8px 0 8px 0;
            h5{
                margin: 0;
                font-size: 18px;
            }
        }
    }
    .ativos-info{
        padding: 15px 0 5px 0;
        span{
            display: block;
        }
    }
}
#root-modal-assinaturas-adquirir-avaliacoes{
    .avaliacoes-lista{
        &-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--grey-100);
            &-info{
                display: flex;
                flex-direction: column;
            }
            &-input{
                width: 150px;
            }
        }
        .flag-primary{
            text-align: center;
            font-size: 16px;
            padding: 10px 10px;
            margin: 25px 0;
        }
    }
    .avaliacoes-info{
        padding: 15px 0 5px 0;
        span{
            display: block;
        }
    }
}
#root-modal-assinaturas-alterar{
    .assinaturas-lista{
        &-item{
            cursor: pointer;
            &.ativo{
                td{
                    background-color: var(--primary-bgh);
                    color: var(--primary-color);
                    border-color: var(--primary-bgh);
                }
            }
        }
    }
}
#root-modal-assinaturas-economizar{
    .assinaturas-lista{
        &-item{
            cursor: pointer;
            &.ativo{
                td{
                    background-color: var(--primary-bgh);
                    color: var(--primary-color);
                    border-color: var(--primary-bgh);
                }
            }
        }
    }
}