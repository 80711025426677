.box-gerenciar-usuarios{
    >h2{
        font-size: 24px;
    }
    .box-content-topinfo{
        >div{
            display: flex;
            align-items: center;
        }
        button{
            display: flex;
            align-items: center;
            + button{
                margin-left: 10px;
            }
            &.circle {
                padding: 8px 15px;
            }
            > svg{
                margin-right: 5px;
            }
        }
    }
    &-add{
        .colflex {
            margin-bottom: 10px;
        }
        .actions{
            padding: 10px !important;
            .form-checkbox {
                margin: 0;
                padding: 0;
                > div{
                    line-height: normal;
                    flex-direction: row;
                    flex-wrap: nowrap;
                }
            }
        }
    }
}




.box-meus-projetos-configurar .box-meus-projetos-topinfo button.circle {
    padding: 8px 15px;
}