.sugestoes-enderecos {
  position: absolute; /* Posiciona a lista abaixo do input */
  top: 100%; /* Posiciona logo abaixo do input */
  left: 0; /* Alinha à esquerda do campo */
  width: 100%; /* Largura igual à do elemento pai */
  list-style: none; /* Remove o marcador de lista */
  padding: 0;
  margin: 4px 0 0 0; /* Um pequeno espaço acima da lista */
  border: 1px solid #ccc; /* Borda visível */
  background-color: #fff; /* Fundo branco */
  max-height: 200px; /* Define altura máxima para evitar overflow */
  overflow-y: auto; /* Adiciona barra de rolagem se necessário */
  z-index: 1000; /* Garante que esteja acima de outros elementos */
}

.sugestoes-enderecos li {
  padding: 8px 12px;
  cursor: pointer;
}

.sugestoes-enderecos li:hover {
  background-color: #f0f0f0; /* Destaque ao passar o mouse */
}
