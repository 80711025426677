.box-popover-central-ajuda {
    display: inline-flex; /* Garante que o texto e o círculo estejam na mesma linha */
    align-items: center; /* Alinha verticalmente o círculo com o texto */
    gap: 8px; /* Espaçamento entre o texto e o círculo */
  }
  
  .box-popover-central-ajuda-head {
    cursor: pointer;
    display: flex;
    align-items: center; /* Garante que o conteúdo dentro do círculo esteja centralizado */
    justify-content: center;
    width: 24px; /* Tamanho do círculo */
    height: 24px;
    border-radius: 50%; /* Torna o elemento circular */
    //background-color: var(--modal-bg); /* Cor de fundo do círculo */
    background-color: transparent; //AJUSTEI TRANSPARANTE PARA FICAR NO HEADER QUE TEM FUNDO DIFERENTE DE BRANCO
    color: var(--modal-color); /* Cor do ícone ou texto dentro do círculo */
    font-size: 16px;
  }
  