:root {
  --white-color: #fff;
  --black-color: #000;
  --dark-color: #333;
  --grey-000: #f9f9f9;
  --grey-100: #e6e6e6;
  --grey-200: #9A9A9A;
  --grey-300: #686868;
  
  --primary-bg: #323ACC;
  --primary-bgh: #4950cf;
  --primary-light: #c7e0fa;
  --primary-color: #ffffff;

  --secondary-bg: #FFFF00;
  --secondary-bgh: #ecec04;
  --secondary-color: #323ACC;

  --success-bg: #00a65a;
  --success-bgh: #028a4d;
  --success-light: #d2fce9;
  --success-color: #fff;

  --error-bg: #dd4b39;
  --error-bgh: #bb3725;
  --error-light: #f7cbcf;
  --error-color: #fff;

  --warning-bg: #f39c12;
  --warning-bgh: #d3860c;
  --warning-light: #f9ecd8;
  --warning-color: #fff;

  --info-bg: #0077DB;
  --info-bgh: #71a9f3;
  --info-light: #c6ddfc;
  --info-color: #333;

  --scrollbar-track: var(--white-color);
  --scrollbar-thumb: var(--grey-200);
  --scrollbar-thumbh: var(--grey-300);
  
  --shadow-color: var(--grey-200);

  --sidebar-bg: var(--white-color);
  --sidebar-color: var(--grey-200);
  --sidebar-menu-bg: var(--white-color);
  --sidebar-menu-bgh: var(--white-color);
  --sidebar-menu-color: var(--primary-bg);

  --header-bg: #F7FAFC;
  --header-color: var(--dark-color);
  --header-menu-bg: #F7FAFC;
  --header-menu-bgh: #F7FAFC;
  --header-menu-color: var(--dark-color);
  
  --page-bg: transparent;
  --page-color: var(--dark-color);
  --page-content-bg: var(--white-color);
  --page-content-color: var(--dark-color);

  --list-bg: transparent;
  --list-bgh: var(--grey-000);
  --list-color: var(--dark-color);
  --list-colorh: var(--dark-color);
  --list-border: var(--grey-100);

  --list-bg-header: var(--grey-000);
  --list-color-header: var(--grey-200);
  --list-border-header: var(--grey-000);




  
  
  --background-page: #fff;
  --color-page: #333;
  --background-container: #f2f2f2;
  --background-page-container: #fff;
  --background-page-container-shadow: #cacaca;

  --background-header: #FFFFFF;
  --background-header-h: #86c33a;
  --background-header-b: #367fa9;
  --color-header: #818385;
  --background-top-header: #252d39;

  --background-sidebar: var(--white-color);
  --background-sidebar-h: var(--primary-bg);
  --background-sidebar-hc: var(--white-color);
  --color-sidebar: #252d39;
  --color-sidebar-h: #fff;

  --background-footer: #fff;
  --background-footer-h: #f1f1f1;
  --color-footer: #252d39;

  --input-bg: #fff;
  --input-bgh: var(--primary-bg);
  --input-color: #252d39;

  --modal-bg: #fff;
  --modal-bgo: #000000c9;
  --modal-bgh: #b8b8b8;
  --modal-color: #252d39;
}
