.list-box {
  &-actionsfooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 25px;
  }
  &-registros{
    background-color: var(--grey-100);
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 10px 15px;
  }
  &-itensperpage{
    min-width: 110px;
  }

}
.box-scrool {
  overflow-y: hidden;
  overflow-x: auto;
  .list-box {
    border-top: 1px solid var(--list-bgh);
    border-spacing: 0;
    color: var(--list-color);
    position: relative;
    width: 100%;
    td {
      border-bottom: 1px solid var(--list-border);
      padding: 15px 10px;
      .alguns{
        background-color: var(--list-bgh);
      }
    }
    thead td {
      border-color: var(--list-border-header);
      background-color: var(--list-bg-header);
      padding: 12px 10px;
      color: var(--list-color-header);
      font-size: 12px;
    }
    tbody tr:hover td {
      background-color: var(--list-bgh);
    }
    .actions {
      white-space: nowrap;
      width: 1%;
      .rigth{
        display: flex;
        justify-content: flex-end;
      }

      button {
        margin-left: 10px;
        &:first-child {
          margin-left: 0px;
        }
      }
    }
    .text-center {
      text-align: center;
    }
    &-header-sort{
      position: relative;
      cursor: pointer;
      &::before,
      &::after{
        content: '';
        border-bottom: 2px solid currentColor;
        border-left: 2px solid currentColor;
        border-color: var(--primary-bg);
        height: 6px;
        width: 6px;
        transition: all 0.1s;
        opacity: 0.4;
        position: absolute;   
        right: 5px;
      }
      &::before{
        transform: rotate(-50deg);
        top: calc(50%);
      }
      &::after{
        transform: rotate(130deg);
        top: calc(50% - 4px);
      }
      &.sort-up::after{
        opacity: 1;
      }
      &.sort-down::before{
        opacity: 1;
      }
    }
  }  
}



@media (max-width: 780px) {
  .list-box {
    &-actionsfooter{
      align-items: flex-start;
      flex-direction: column;
      >div{
        &.list-box-paginacao{
          text-align: center;
          margin: 20px auto;
        }
      }
    }
  }
}