.box-popover{
  position: relative;
  display: inline-flex;
  &-head{
    cursor: pointer;
  }
  &-content{
    position: absolute;    
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-bgh);
    border-radius: 4px;
    color: var(--modal-color);
    min-width: 250px;
    z-index: 1;
    padding: 5px 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
}