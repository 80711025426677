.box-dropdown{
  position: relative;
  &-content{
    position: absolute;    
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-bgh);
    border-radius: 4px;
    color: var(--modal-color);
    min-width: 250px;
    z-index: 1;
    padding: 5px 0;
  }
}