.form-select-box{
  select{
    background-color: transparent;
    border: none;
    border-radius: 50px;
    color: currentColor;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    justify-content: space-between;
    padding: 8px 0px 8px 6px;
    width: 100%;
    align-items: center;
    // appearance: none;
     .select-option{
      border-top: 1px solid var(--background-page-container-shadow);
      color: var(--input-color);
      cursor: pointer;
      padding: 6px 10px;
      position: relative;
      &:active{
        background-color: var(--primary-bgh);
        color: var(--primary-color);
      }
    }
  }
}
