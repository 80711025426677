.box-public{
    &.box-banco-projetos{
        border-radius: 8px;
        background-color: #fff;
        padding: 0px 2px;
        margin: 0px 1px;
        .box-banco-projetos-container{
            height: calc(100vh - 47px);
            // height: calc(600px);
            overflow: scroll;
        }
    }
}

@media (max-width: 600px){
    .box-public{
        &.box-banco-projetos{
            .box-banco-projetos{
                &-projeto{
                    margin: 10px 4px 5px 4px !important;
                }
                &-container{
                    height: calc(100vh - 88px);
                    // height: calc(600px);
                    overflow: scroll;
                }
            }
        }
    }

}

@media (min-width: 601px) and (max-width: 990px) {
    .box-public{
        &.box-banco-projetos{
            .box-banco-projetos-container{
                height: calc(100vh - 39px);
                // height: calc(600px);
                overflow: scroll;
            }
        }
    }
}