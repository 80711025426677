#box-header {
  background-color: var(--header-bg);
  color: var(--header-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;
  min-height: 30px;
  position:relative;
  box-shadow: 0px 5px 15px 1px rgb(0 0 0 / 10%);
  button{
    position: relative;
    .flag{
      background-color: var(--primary-bg);
      position: absolute;
      top: -3px;
      right: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50px;
    }
  }
  > div {
    padding: 0 15px;
  }
  .box-logo{
    height: 60px;
    padding: 10px 15px;

    display: flex;
    align-items: center;
    > div{
      margin-right: 10px;
      svg{
        max-width: 150px;
      }
    }
    h1{
      color: var(--header-color);
      font-size: 24px;
      font-weight: 800;
      margin: 0;
    }
    > img{
      width: 100%;
      max-width: 118px;
    }
  }
  .btn-header {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0px;
    color: var(--header-menu-color);
    cursor: pointer;
    display: inline-block;
    font-size: 1.0em;
    padding: 8px 5px;
    transition: all 0.15s;
    .btFlex{
      display: flex;
      align-items: center;
    }
    svg {
      display: flex;
      font-size: 1.5em;
    }
    :hover,
    .open {
      background-color: var(--header-menu-bg);
      color: var(--header-menu-color);
    }
  }
  .box-user-info{
    display: flex;
    align-items: center;
    p{
      margin: 0;
      border-right: 1px solid var(--grey-100);
      padding: 10px 15px;
      height: 65%;
    }
    button{
      display: flex;
      align-items: center;
      padding: 15px 15px 8px 15px;
      opacity: 1;
      >svg{
        padding: 0 5px;
        font-size: 28px;
      }
      &:hover{
        color: var(--primary-bg);
        >svg{
          color: var(--primary-bg);
        }
      }
    }
  }
  .box-header-menu{
    display: flex;
    align-items: center;
    .btn-header{
      &:hover{
        color: var(--primary-bg);
      }
    }
    button{
      +button{
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: 780px) {
  #box-header{
    .btn-menu {
      display: none;
    }
  }
}

@media (max-width: 779px) {
  #box-header{
    .box-user-info{
      position: absolute;
      top: 60px;
      background-color: var(--page-content-bg);
      z-index: 2;
      flex-direction: column;
      width: 100%;
      display: none;
      &.open-box{
        display: flex;
        p{
          border: none;
          margin: 15px 0 20px 0;
        }
        button{
          margin: 10px 0;
        }
      }
    }
    .box-logo h1{
      font-size: 17px;
      font-weight: 600;
    }
  }
}



.box-modal-info-user{
  position: absolute;
  right: 15px;
  background-color: var(--background-page);
  box-shadow: 0px 3px 5px 2px var(--grey-100);
  border: 1px solid var(--grey-100);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 15px 0 0 0;
  z-index: 1;
  min-width: 300px;
  &-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    p{
      margin: 0;
    }
  }
  &-info{
    .progress-bar{
      padding: 4px 15px;
      >div{
        &:first-child{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 3px;
        }
        &:nth-child(2){
          background-color: var(--grey-100);
          border-radius: 5px;
          width: 100%;
          overflow: hidden;
          >div{
            height: 8px;
          }
        }
      }
    }
    .btnvermais{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: var(--grey-200);
      cursor: pointer;
      &:hover{
        color: var(--primary-bg);
      }
    }
  }
  &-menu{
    >div{
      display: flex;
      align-items: center;
      padding: 10px 15px;
      cursor: pointer;
      > svg{
        margin-right: 10px;
      }
      &:last-child{
        padding: 30px 15px 15px 15px;
      }
      &:hover{
        color: var(--primary-bg);
      }
    }
  }
}

.box-modal-notificacoes{
  position: absolute;
  right: 90px;
  background-color: var(--background-page);
  box-shadow: 0px 3px 5px 2px var(--grey-100);
  border: 1px solid var(--grey-100);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 15px 0 0 0;
  z-index: 1;
  min-width: 300px;
  max-width: 300px;
  &-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 15px 10px 15px;
    button{
      color: var(--grey-200);
    }
    h6{
      font-size: 14px;
    }
    >*{
      margin: 0;
    }
  }
  &-content{
    .box-notificacao{
      +.box-notificacao{
        margin-top: 30px;
      }
      margin: 0 15px;
      &-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
          color: var(--grey-200);
        }
        >*{
          font-size: 13px;
          margin: 0;
        }
      }
      &-sem{
        text-align: center;
        margin: 30px 0;
        display: block;
      }
    }
  }
}