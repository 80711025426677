.box-wizard{
  display: block;
  position: relative;
  min-width: 20px;
  &-base{
    padding: 7px 0;
    &::after{
      background-color: var(--grey-100);
      display: block;
      height: 6px;
      border-radius: 50px;
      content: '';
    }
  }
  &-bar{
    background-color: var(--secondary-bg);
    border: 2px solid var(--primary-bg);
    border-radius: 50px;
    height: 14px;
    position: absolute;
    width: 0;
    top: 3px;

  }
}