.box-questionario{
    &-frame{
        background-image: url('../../layout/img/login/bg-2.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        background-color: #242525;
        height: 100vh;
        overflow: hidden;
        >img{
            height: auto;
            max-height: 100%;
            width: auto;
            max-width: 100%;
        }
    }
    &-form{
        width: 50%;
        height: 100vh;
        overflow: auto;
    }
    &-logo{
        margin-bottom: 40px;
        max-width: 230px;
        >img{
            width: 100%;
        }
    }
    &-padlock{
        height: 34px;
        margin: -12px 0 20px 0;
        >img{
            height: 100%;
        }
    }
    &-info{
        margin-bottom: 16px;
        h2{
            color: var(--primary-bg);
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            margin: 0;
        }
        h4{
            font-size: 26px;
            font-weight: 800;
            line-height: 30px;
        }
        p{
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
        }
    }
    &-container{
        display: flex;
        margin: auto;
        flex-direction: row;
        height: 100vh;
        align-items: center;
        justify-content: space-between;
    }
    &-content{
        max-width: 510px;
        margin: auto;
        padding: 30px 20px;
        &-pergunta{
            color: var(--primary-bg);
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
        }
    }
    &-action{
        // margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        small{
            font-size: 11px;
        }
        >div{
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        >button{
            margin-top: 40px; 
        }
        &-btns{
            margin-top: 30px !important; 
            justify-content: flex-start !important;
            
            >button{
                // width: calc(50% - 10px); 
                +button{
                    margin-left: 10px;
                }
            }
        }
    }

    &-checkboxes{
        max-height: calc(100vh - 315px);
        overflow: auto;
        .form-checkbox{
            flex-direction: column;
            align-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            >div{
                background-color: var(--white-color);
                border: 2px solid var(--primary-bg);
                border-radius: 6px;
                padding: 10px;
                margin-bottom: 10px;
                min-width: 150px;
                cursor: pointer;
            }
            .wrapper-checked{
                background-color: var(--primary-bg);
                .checkbox{
                    border-color: var(--secondary-bg);
                    &::after{
                        border-color: var(--secondary-bg);
                    }
                }
                >div{
                    color: var(--secondary-bg);
                }
            }
        }
    }
    .box-wizard{
        margin: 20px 0;
    }
}


@media (max-width: 780px) {
    .box-questionario{
        &-frame{
            display: none !important;
        }
        &-form{
            width: 100% !important;
        }
    }
  }