.form-box{
  .form-inputfile{
  }
  .inputfile{
    position: relative;
    cursor: pointer;
    width: 100%;
    >div{
      display: flex;
      align-items: center;
      min-height: 35px;
    }
    &-arcuive{
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--grey-100);
      max-width: 100px;
      img{
        width: 100%;
        height: auto;
      }
      svg{
        font-size: 40px;
      }
    }
    input{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  &.form-input-date{
    >div{
      padding: 0 2px;
      border: 2px solid var(--input-bgh);
    }
  }
}
