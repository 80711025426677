.box-upgrade-conta{
    max-width: 1200px;
    margin: 50px auto 0 auto;
    text-align: center;
    &-topinfo{
        h2{
            color: var(--primary-bg);
            font-weight: 500;
            margin-bottom: 20px;
        }
    }
    .box-boxes{
        display: flex;    
        align-items: stretch;
        justify-content: center;
        padding: 10px 20px;
        &-box{
            background-color: var(--primary-bg);
            color: var(--primary-color);
            border-radius: 8px;
            margin: 10px 10px;
            width: calc((100% - 80px) / 3);
            min-width: calc((100% - 80px) / 3 );
            padding: 20px 10px 10px 10px;
        }
        &-img{
            width: 100%;
            height: auto;
            margin-top: 10px;
            img{
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        p{
            margin: 0 20px 10px;
        }
    }
    button{
        margin: 50px 0;
    }

}

#root-modal-comprar-ativos{
    .periodos-lista{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div{
            display: flex;
            align-items: center;
            .btn.circle{
                margin-left: 6px;
                padding: 6px 15px;
            }
        }
        border-bottom: 1px solid var(--grey-100);
        padding: 0 0 20px 0;
    }
    .ativos-lista{
        &-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--grey-100);
            &-info{
                display: flex;
                flex-direction: column;
            }
            &-input{
                width: 150px;
            }
        }
        .flag-primary{
            text-align: center;
            font-size: 16px;
            padding: 10px 10px;
            margin: 25px 0;
        }
        &-title{
            padding: 8px 0 8px 0;
            h5{
                margin: 0;
                font-size: 18px;
            }
        }
    }
    .ativos-info{
        padding: 15px 0 5px 0;
        span{
            display: block;
        }
    }
}
@media (max-width: 576px) {
    .box-upgrade-conta{
        max-width: 1200px;
        margin: 50px auto 0 auto;
        text-align: center;
        .box-boxes{
            flex-direction: column;
            &-box{
                width: 100%;
                max-width: 100%;
            }
        }
    }

}