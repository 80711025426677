.box-meus-projetos-configurar{
    &-search{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0 30px 0;
        >div{
            min-width: 200px;
            &:last-child{
                min-width: 350px;
                .input-actions-left{
                    button{
                        display: flex;
                    }
                }
            }
        }
    }
    .box-meus-projetos-topinfo{
        > div{
            display: flex;
            align-items: center;
        }
        button{
            display: flex;
            align-items: center;
            svg{
                margin-right: 5px;
            }
            &.circle{
                padding: 8px 15px;
            }
        }
    }
    .actions-tab{
        button{
            &.default{
                border-color: transparent;
            }
            + button{
                margin-left: 15px;
            }
        }
    }
    [class*='flag-']{
        white-space: nowrap;
    }
}
    
.modal-meuProjeto-empresas-add{
    .inputfile{
        color: var(--grey-200);
        span{
            display: flex;
            align-items: center;
            svg{
              margin-left: 10px;
            }
        }
    }
}
.modal-meuProjeto-importar{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    .icon-importar{
        font-size: 200px;
    }
    .filearchive{
        position: relative;
        input{
            position: absolute;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
        >div{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        span{
            margin: 10px 0;
        }
        
    }
    &>div:last-child{
        > button {
            margin: 20px 0 30px 0;
        }

    }
}
.modal-meuProjeto{
    &-estabelecimentos{
        &-add{
            #grupo-estabelecimento-add{
                margin: 20px 0 0 10px
            }
        }
        &-verificar{
            .informe{
                color: var(--grey-300)
            }
            >div{
                display: flex;
                flex-direction: column;
                >div{
                    border: 2px solid transparent;
                    border-radius: 8px;
                    padding: 10px;
                    &.active{
                        border-color: var(--primary-bg);
                    }
                }
                label{
                    display: block;
                    font-weight: bold;
                }
                span{
                    color: var(--grey-200)
                }
            }
        }
    }
    &-produtos{
        &-add{
            #grupo-produto-add{
                margin: 20px 0 0 10px
            }
        }
    }
}
.modal-meuProjeto-grupos-add{
    &-form{
        padding: 5px 10px;
        display: flex;
        // align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        >div{
            &:first-child{
                width: 100%;
            }
            &:last-child{
                margin-left: 10px;
            }
        }
    }
}
.action-grupo{
    display: inline-block;
}
#root-box-addedit-grupo{
    position: relative;
}
.box-addedit-grupo{
    position: absolute;    
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-bgh);
    border-radius: 4px;
    color: var(--modal-color);
    max-width: 300px;
    z-index: 1;
    padding: 5px 0;
    display: none;
    button + button{
        margin-left: 10px;
    }
}