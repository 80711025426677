.modal-modalAlerta{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .modalAlerta-btns{
    margin: 10px 0 10px 0;
    button{
      min-width: 200px;
    }
  }
  strong{
    font-size: 16px;
    margin-bottom: 5px;
  }
  &-img{
    margin-bottom: 10px;
  }
}