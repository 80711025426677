.box-swot{
    &-infoindicadores{
        color: var(--grey-300);
        display: block;
        margin: 0 5px 10px 5px;
    }
    h3{
        font-size: 20px;
    }
    &-topinfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        label{
            color: var(--grey-300);
            margin: 0;
        }
        >div{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        &-select{
            margin-right: 20px;
            .form-select-box{
                display: flex;
                align-items: center;
                >div{
                    margin-left: 5px;
                    // max-width: 150px;
                }
            }
        }
        &-avaliacao{
            label{
                margin-right: 5px;
            }
            strong{
                margin-left: 5px;
            }
        }
    }
    &-content{
        padding: 10px;
        &-title{
            font-size: 20px;
            font-weight: 800;
            line-height: 24px;
            margin-bottom: 20px;
        }
        &-info{
            margin-bottom: 25px;
            h3{
                font-size: 14px;
                font-weight: 800;
                line-height: 24px;
                svg{
                    font-size: 17px;
                }
            }
            &-list{
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                &-loja{
                    background-color: var(--grey-000);
                    color: var(--grey-200);
                    border-radius: 5px;
                    padding: 6px;
                    margin-right: 15px;
                    font-size: 12px;
                }
                p{
                    color: var(--grey-200);
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
    }
}

.plano7dias-content {
    background-color: white; /* Fundo branco */
    padding: 50px; /* Espaçamento interno */
    border-radius: 10px; /* Bordas arredondadas */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para destaque */
    margin: 20px 0; /* Espaçamento externo para separar dos outros conteúdos */
    font-family: "Quicksand", sans-serif;
  
    &-title {
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      margin-bottom: 20px;
    }
  
    &-text {
      font-size: 16px;
      line-height: 1.5;
      color: var(--grey-300);
      margin-bottom: 20px;
    }
  
    &-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        font-size: 14px;
    
        th, td {
          border: 1px solid #ccc;
          padding: 8px;
          text-align: left;
        }
    
        th {
          background-color: #f2f2f2;
        }
    }
  }
  



@media (max-width: 779px) {
    .box-swot{
        &-topinfo{
            flex-direction: column;
            &-select{
                .form-select-box{
                    flex-direction: column;
                }
            }
        }
    }
}