.box-central-ajuda {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 15px;
      color: #323ACC;
  
      h4 {
        margin: 0;
        font-size: 20px;
      }
  
      button {
        display: flex;
        padding: 5px;
      }
    }
  
    &-content {
      margin: 10px 15px;
      flex-grow: 1; /* Permite que o conteúdo ocupe o espaço disponível */
      overflow-y: auto; /* Habilita o scroll vertical */
      max-height: 500px; /* Limita a altura máxima para rolagem */
  
      &::-webkit-scrollbar {
        width: 8px; /* Largura da barra de rolagem */
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3); /* Cor da barra de rolagem */
        border-radius: 4px;
      }
  
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* Fundo da barra de rolagem */
      }
  
      &-info {
        margin: 0 0 30px 0;
  
        h6 {
          font-size: 16px;
          margin: 0;
        }
  
        p {
          font-size: 13px;
        }
  
        button {
          display: flex;
          align-items: center;
          font-size: 13px;
  
          svg {
            margin-left: 5px;
            font-size: 10px;
          }
        }
      }
    }
  
    &-btns {
      margin: 20px 15px;
  
      button {
        display: flex;
        align-items: center;
  
        svg {
          margin-right: 5px;
        }
      }
    }
  
    .textosite {
      color: #181B57;
    }
    .link {
      text-decoration: underline; /* Deixa o texto sublinhado */
      color: #181B57; //#181B57 #007bff
      cursor: pointer;
  
      &:hover {
        color: #0056b3;
      }
    }

    .custom-spacing {
      margin-bottom: 5px; /* Ajuste o valor para o espaçamento desejado */
    }
  }
  