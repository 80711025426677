[id*='root-modal-'] {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  .box-modal {
    background-color: var(--modal-bgo);
  
    display: flex;
    height: 100vh;
    overflow: auto;
    padding: 10px 0 20px 0;
    width: 100%;
  }
  [class*='size-'] {
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-bgh);
    border-radius: 4px;
    color: var(--modal-color);
  
    display: block;
    margin: auto;
    width: 100%;
  }
  .modal-header {
    align-items: center;
    // border-bottom: 1px solid var(--modal-bgh);
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
  
    font-size: 1.6em;
    color:  var(--modal-color);
    font-weight: 700;
  }
  .fullscreen {
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-bgh);
    color: var(--modal-color);
    border-radius: 0px;
    height: 100vh;
    padding: 0;
    width: 100vw;
    .modal-content {
      overflow: auto;
      height: calc(100% - 60px);
    }
  }
  .modal-content {
    font-size: 1em;
    padding: 10px 15px;
    + .modal-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // border-top: 1px solid var(--modal-bgh);
      padding: 10px 15px;
      > button {
        margin-left: 10px;
      }
    }
  }
  .modal-close {
    align-items: center;
    align-self: flex-start;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    color: var(--modal-color);
    cursor: pointer;
    display: flex;
    font-size: 20px;
    justify-content: center;
    min-height: 25px;
    min-width: 25px;
    padding: 0;
    &:hover {
      background-color: var(--modal-bgh);
      color: var(--modal-color);
      opacity: 0.7;
    }
    &:focus {
      position: relative;
      top: 1px;
      outline: none;
    }
  }
  .size-small {
    max-width: 300px;
  }
  .size-medium {
    max-width: 650px;
  }
  .size-large {
    max-width: 800px;
  }
  .size-large-full {
    max-width: 90%;
  }
}