.modal-criarMeuProjeto{
    h3{
        font-size: 18px;
        text-align: center;
    }
    .type-btns{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 30px 0;
    
        >div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            >div{
                border-radius: 10px;
                font-size: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                width: 80px;
                height: 80px;
                margin-bottom: 10px;
            }
            &:hover{
                >div{
                    background-color: var(--primary-bg);
                    color: var(--primary-color);
                }
                span{
                    color: var(--primary-bg);
                    display: block;
                }
            }
        }
    }
    .form-box{
        .form-icones{
            >div{
                border: 4px solid transparent;
                border-radius: 50px;
                margin: 0px 5px;
                cursor: pointer;
                &:hover{
                    opacity: 0.7;
                }
                &.active{
                    border-color: var(--primary-bg);
                }
            }
        }
    }
}